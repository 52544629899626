import axios from 'axios';

interface Environment {
  cognitoUserPoolId: string;
  cognitoUserPoolWebClientId: string;
  cognitoRegion: string;

  nodeURL: string;
  autocomplete?: boolean;
}

const alpha: Environment = {
  cognitoUserPoolId: 'eu-west-2_FNMxZkY0O',
  cognitoUserPoolWebClientId: '52ecaghv05fvf1qnhaqd9gv1c8',
  cognitoRegion: 'eu-west-2',

  nodeURL: 'https://alpha.app.iamyiam.com/api/v2',
};

const dev: Environment = {
  ...alpha,

  nodeURL: 'http://localhost:8000/api/v2',
  autocomplete: true,
};

const prod: Environment = {
  cognitoUserPoolId: 'eu-west-2_6YqPkSazd',
  cognitoUserPoolWebClientId: '3de8prgg2khpp20b8gco312995',
  cognitoRegion: 'eu-west-2',

  nodeURL: 'https://www.iamyiam.com/api/v2',
};

const environments = {
  alpha,
  dev,
  prod,
};

export const appEnvironment =
  environments[process.env.REACT_APP_ENVIRONMENT as keyof typeof environments];

export const nodeClient = axios.create({ baseURL: appEnvironment.nodeURL });
