import { Button, Stack, TextField, ThemeProvider } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { appEnvironment, nodeClient } from './common/environments';
import { theme } from './common/theme';
import { loginStyles } from './styles';

function App() {
  const [email, setEmail] = useState(
    appEnvironment.autocomplete ? 'oo@oo.com' : ''
  );
  const [password, setPassword] = useState(
    appEnvironment.autocomplete ? 'Password@2' : ''
  );

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [searchParams] = useSearchParams();

  const onEmailBlur = useCallback(() => {
    if (email.length === 0) {
      setEmailError('This field is required');
    }
  }, [email.length]);

  const onPasswordBlur = useCallback(() => {
    if (password.length === 0) {
      setPasswordError('This field is required');
    }
  }, [password.length]);

  const onChange = useCallback(
    (setField: (value: string) => void, setError: (value: string) => void) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setError('');
        setField(event.target.value);
      };
    },
    []
  );

  const hasErrors = useMemo(() => {
    return email.length === 0 || password.length === 0;
  }, [email.length, password.length]);

  const onLoginClick = useCallback(() => {
    onEmailBlur();
    onPasswordBlur();

    if (!hasErrors) {
      Auth.signIn(email, password)
        .then(() => {
          Auth.currentSession()
            .then((session) => {
              const accessToken = session.getAccessToken().getJwtToken();
              const refreshToken = session.getRefreshToken().getToken();

              nodeClient
                .post<{ code: string }>(
                  'oauth/login',
                  { refreshToken },
                  { headers: { 'access-token': accessToken } }
                )
                .then(({ data: { code } }) => {
                  window.location.href = `${searchParams.get(
                    'redirect_uri'
                  )}?state=${searchParams.get('state')}&code=${code}`;
                });
            })
            .catch(() => {});
        })
        .catch(() => alert('Invalid credentials'));
    }
  }, [email, hasErrors, onEmailBlur, onPasswordBlur, password, searchParams]);

  return (
    <ThemeProvider theme={theme}>
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={'15px'}
        sx={loginStyles.container}
      >
        <TextField
          sx={loginStyles.input}
          label="Email"
          value={email}
          InputProps={{ inputMode: 'email', autoCapitalize: 'none' }}
          error={emailError.length > 0}
          onBlur={onEmailBlur}
          helperText={emailError}
          onChange={onChange(setEmail, setEmailError)}
          type="email"
        />

        <TextField
          type="password"
          label="Password"
          value={password}
          sx={loginStyles.input}
          error={passwordError.length > 0}
          helperText={passwordError}
          onBlur={onPasswordBlur}
          onChange={onChange(setPassword, setPasswordError)}
        />

        <Button
          variant="contained"
          sx={{ width: '200px' }}
          onClick={onLoginClick}
          disabled={hasErrors}
        >
          LOGIN
        </Button>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
